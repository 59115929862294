<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-modal v-model="showModal" title="Verification des informations" class="col-12" hide-footer>
              <form>
                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Infraction</label>
                  <div class="col-lg-10">
                    <multiselect disabled class="test-primary" v-model="data.TypeContravention" :options="contras"
                      searchable></multiselect>
                  </div>
                </div>

                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Type de vehicule</label>
                  <div class="col-lg-10">
                    <multiselect disabled class="test-primary" v-model="data.Typevehicule" :options="typeVehicule"
                      searchable></multiselect>
                  </div>
                </div>

                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Plaque</label>
                  <div class="col-lg-7">
                    <input disabled v-model="data.immatriculation" id="immatriculation" name="immatriculation" type="text"
                           class="form-control" />
                  </div>
                  <div class="col-lg-3">
                    <input disabled v-model="data.paysCode" id="code" name="code" type="text" class="form-control" />
                  </div>

                </div>

                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Code agent</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.agent" id="agent" name="agent" type="text" class="form-control" />
                  </div>
                </div>

                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Lieu
                  </label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.LieuInfraction" id="lieu" name="lieu" type="text"
                      class="form-control" />
                  </div>
                </div>

                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Date</label>
                  <div class="col-lg-10">
                    <datepicker disabled v-model="data.receiveDate" :first-day-of-week="1" lang="en" confirm
                      class="form-control">
                    </datepicker>
                  </div>
                </div>

                <div class="row justify-content-end">
                  <div class="col-md-10">
                    <button type="button" @click="data = {}; showModal = false" class="btn btn-primary">
                      Annuler
                    </button>
                    <button @click="addContravention()" type="button" class="btn btn-success" style="float: right"
                      :disabled="spanView">
                      <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                      Valider
                    </button>
                  </div>
                </div>
              </form>
            </b-modal>
            <form @submit.prevent="submit">
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Infraction</label>
                <div class="col-lg-10">
                  <multiselect class="test-primary" v-model="data.TypeContravention" :options="contras" searchable></multiselect>
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Type de vehicule</label>
                <div class="col-lg-10">
                  <multiselect class="test-primary" v-model="data.Typevehicule" :options="typeVehicule" searchable></multiselect>
                </div>
              </div>


              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Numéro de plaque</label>
                <div class="col-lg-7">
                  <input v-model="data.immatriculation" id="immatriculation" name="immatriculation" type="text"
                         class="form-control" placeholder="numero immatriculation,  Exemple: AK-7834" />
                </div>
                <div class="col-lg-3">
                  <input v-model="data.paysCode" id="code" name="code" type="text" class="form-control"
                    placeholder="code pays,  Exemple: RT" />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Code agent</label>
                <div class="col-lg-10">
                  <input v-model="data.agent" id="agent" name="agent" type="text" class="form-control"
                    placeholder="Renseignez le code de l'agent..." />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Lieu
                </label>
                <div class="col-lg-10">
                  <input v-model="data.LieuInfraction" id="lieu" name="lieu" type="text" class="form-control"
                    placeholder="Indiquez le lieu de l'infraction..." />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Date</label>
                <div class="col-lg-10">
                  <datepicker v-model="data.receiveDate" :first-day-of-week="1" lang="en" confirm class="form-control"
                    placeholder="Date de l'infraction...">
                  </datepicker>
                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col-md-10">
                  <button type="reset" @click="data = {}" class="btn btn-primary">
                    Annuler
                  </button>
                  <button type="submit" class="btn btn-success" style="float: right" :disabled="spanView">
                    <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                    Valider
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
// import { DropZone } from 'dropzone-vue';
import Datepicker from "vue3-datepicker";
import Multiselect from "@vueform/multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';
import Swal from "sweetalert2";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";


export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    Datepicker,
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      contraData: [],
      title: "Enregistrer une contravention",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Contraventions",
          active: true,
        },
      ],
      contras: [],
      typeVehicule: ["vehicule", "moto"],
      contrav: [],
      showModal: false,
      submitted: false,
      data: {
        immatriculation: null,
        TypeContravention: null,
        Typevehicule: null,
        LieuInfraction: null,
        agent: null,
        date_is_receive: null,
        receiveDate: null,
      },
      receiveDate: null,
      spanView: false,
      reqError: null,
      response: null,
    };
  },
  validations: {
    data: {
      TypeContravention: {
        required: helpers.withMessage("Veuillez choisir l'infraction", required),
      },
      immatriculation: {
        required: helpers.withMessage("Veuillez renseigner le numero de la plaque", required),
      },
      agent: {
        required: helpers.withMessage("Veuillez renseigner le code de l'agent", required),
      },
      LieuInfraction: {
        required: helpers.withMessage("Veuillez renseigner le lieu de l'infraction", required),
      },
      receiveDate: {
        required: helpers.withMessage("Veuillez renseigner la date de l'infraction", required),
      },

    }
  },
  methods: {
    ...reqMethods,
    submit() {
      this.submitted = true;
      this.showModal = true;
    },
    addContravention() {
      this.showModal = false
      this.spanView = true;
      this.data.date_is_receive = this.data.receiveDate?.toISOString().split('T')[0];
      if (this.data.paysCode) {
        this.data.immatriculation = this.data.immatriculation + "-" + this.data.paysCode;
      } else {
        this.data.immatriculation = this.data.immatriculation + "-RT";
      }
      const that = this;
      this.postRequest({
        route: '/contravention/contravention/',
        data: this.data
      }).then(
        function (response) {
          that.spanView = false;
          that.response = response.data;
          Swal.fire(response.data.message, "La Contravention à été Ajouté!", "success");
          that.$router.push('/liste-contravention');
        },
        function (error) {
          that.spanView = false;
          that.reqError = error;
          Swal.fire({
            title: "Oops...",
            text: "Erreur lors de l'ajout de la Contravention",
            icon: "error",
            confirmButtonClass: "btn btn-primary w-xs mt-2",
            buttonsStyling: false,
            showCloseButton: true,
          });
        }
      )
    },
    getAllContraventionTypes() {
      const that = this;
      this.getRequest('/contravention/type-contravention/')
        .then(
          function (response) {
            that.contraData = response.data;
            that.contraData.forEach(element => {
              that.contras.push({ value: element.id, label: element.code + '-' + element.nomType });
            });
            that.contrav = that.contras;
          },
          function (error) {
            that.reqError = error;
          }
        )
    },

  },
  mounted() {
    this.getAllContraventionTypes();
  },
};
</script>